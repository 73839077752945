import { gql } from "@apollo/client/core";

export const FRAGMENT_USER = gql`
    fragment FragmentUser on User {
        uuid
        email
        isVIP
        isAdmin
        lastSeen
        updatedAt
        createdAt
    }
`;