import { QueryOptions, useTeamfightQuery } from "~/graphql";
import { getPlatformNoticeQuery } from "~/graphql/query/getPlatformNotice/getPlatformNotice.query";
import { PlatformNotice } from "~/types/general";

interface Return {
  getPlatformNotice: PlatformNotice | null;
}

export async function getPlatformNotice(options?: QueryOptions) {
  const result = await useTeamfightQuery<Return>({ query: getPlatformNoticeQuery, options });
  return { ...result, data: computed<PlatformNotice | null | undefined>(() => result.result.value?.getPlatformNotice) };
}