import * as Sentry from "@sentry/vue";
import version from "../version.json";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const integrations = [];

  // We only want browser tracing on client-side.
  if (process.client) {
    integrations.push(Sentry.browserTracingIntegration());
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    environment: config.public.environment,
    dsn: config.public.sentry_dsn,
    integrations,
    tracesSampleRate: 1.0,
    release: version.build
  });
});