import { defineStore } from "pinia";
import { openContextMenu } from "~/stores/ContextMenuStore/actions/openContextMenu";
import { closeContextMenu } from "~/stores/ContextMenuStore/actions/closeContextMenu";

interface State {
  positionX: number | null;
  positionY: number | null;
  options: ContextMenuOption[][];
}

export interface ContextMenuOption {
  label: string;
  action?: () => void;
  icon?: string;
  image?: string;
  subOptions?: ContextMenuOption[];
}

export const useContextMenu = defineStore("context-menu", {
  state: (): State => ({
    positionX: null,
    positionY: null,
    options: []
  }),

  actions: {
    openContextMenu,
    closeContextMenu,
  }
});
