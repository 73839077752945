import { gql } from "@apollo/client/core";

export const getPlatformNoticeQuery = gql`
    query GetPlatformNotice {
        getPlatformNotice {
            uuid
            text
            severity
            isActive
            updatedAt
            createdAt
        }
    }
`;
