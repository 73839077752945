import {
  TFT_SET_1,
  TFT_SET_10,
  TFT_SET_11,
  TFT_SET_12,
  TFT_SET_2,
  TFT_SET_3,
  TFT_SET_3_5,
  TFT_SET_4,
  TFT_SET_4_5,
  TFT_SET_5,
  TFT_SET_5_5,
  TFT_SET_6,
  TFT_SET_6_5,
  TFT_SET_7,
  TFT_SET_7_5,
  TFT_SET_8,
  TFT_SET_8_5,
  TFT_SET_9,
  TFT_SET_9_5,
  TFT_TUTORIAL,
  TFTSet
} from "~/data/sets";
import { RiotPlatformRegion } from "~/types/riot";
import { patch } from "~/utils/patch";

export function getSets(): TFTSet[] {
  return [
    TFT_TUTORIAL,
    TFT_SET_1,
    TFT_SET_2,
    TFT_SET_3,
    TFT_SET_3_5,
    TFT_SET_4,
    TFT_SET_4_5,
    TFT_SET_5,
    TFT_SET_5_5,
    TFT_SET_6,
    TFT_SET_6_5,
    TFT_SET_7,
    TFT_SET_7_5,
    TFT_SET_8,
    TFT_SET_8_5,
    TFT_SET_9,
    TFT_SET_9_5,
    TFT_SET_10,
    TFT_SET_11,
    TFT_SET_12
  ];
}

export const getSetFromPatch = (patch?: string): TFTSet => {
  if (!patch || patch === "latest") {
    return TFT_SET_LATEST;
  }

  const [season, minorPatch] = patch.split(".").map((v) => parseInt(v.replace('b', '')));

  if (!season || !minorPatch) return TFT_SET_LATEST;

  // Set 1: Faction Wars (9.14 -> 9.21)
  if (season === 9 && minorPatch >= 14 && minorPatch <= 21) {
    return TFT_SET_1;
  }

  // Set 2: Rise of the Elements (9.22 -> 10.5)
  if (
      (season === 9 && minorPatch >= 22) ||
      (season === 10 && minorPatch >= 1 && minorPatch <= 5)
  ) {
    return TFT_SET_2;
  }

  // Set 3: Galaxies (10.6 -> 10.11)
  if (season === 10 && minorPatch >= 6 && minorPatch <= 11) {
    return TFT_SET_3;
  }

  // Set 3.5: Galaxies: Return to the Stars (10.12 -> 10.18)
  if (season === 10 && minorPatch >= 12 && minorPatch <= 18) {
    return TFT_SET_3_5;
  }

  // Set 4: Fates (10.19 -> 11.1)
  if (
      (season === 10 && minorPatch >= 19) ||
      (season === 11 && minorPatch === 1)
  ) {
    return TFT_SET_4;
  }

  // Set 4.5: Fates: Festival of Beasts (11.2 -> 11.8)
  if (season === 11 && minorPatch >= 2 && minorPatch <= 8) {
    return TFT_SET_4_5;
  }

  // Set 5: Reckoning (11.9 -> 11.14)
  if (season === 11 && minorPatch >= 9 && minorPatch <= 14) {
    return TFT_SET_5;
  }

  // Set 5.5: Reckoning: Dawn of Heroes (11.15 -> 11.21)
  if (season === 11 && minorPatch >= 15 && minorPatch <= 21) {
    return TFT_SET_5_5;
  }

  // Set 6: Gizmos & Gadgets (11.22 -> 12.3)
  if (
      (season === 11 && minorPatch >= 22) ||
      (season === 12 && minorPatch >= 1 && minorPatch <= 3)
  ) {
    return TFT_SET_6;
  }

  // Set 6.5: Gizmos & Gadgets: Neon Nights (12.4 -> 12.10)
  if (season === 12 && minorPatch >= 4 && minorPatch <= 10) {
    return TFT_SET_6_5;
  }

  // Set 7: Dragonlands (12.11 -> 12.16)
  if (season === 12 && minorPatch >= 11 && minorPatch <= 16) {
    return TFT_SET_7;
  }

  // Set 7.5: Dragonlands: Uncharted Realms (12.17 -> 12.22)
  if (season === 12 && minorPatch >= 17 && minorPatch <= 22) {
    return TFT_SET_7_5;
  }

  // Set 8: Monsters Attack (12.23 -> 13.5)
  if ((season === 12 && minorPatch >= 23) || (season === 13 && minorPatch <= 5)) {
    return TFT_SET_8;
  }

  // Set 8.5: Monsters Attack: Glitched Out (13.6 -> 13.12)
  if (season === 13 && (minorPatch >= 6 && minorPatch <= 11)) {
    return TFT_SET_8_5;
  }

  // Set 9: Runeterra Reforged  (13.12 -> 13.17)
  if (season === 13 && (minorPatch >= 12 && minorPatch <= 17)) {
    return TFT_SET_9;
  }

  // Set 9.5: Runeterra Reforged: Horizon Bound (13.18 -> 13.22)
  if (season === 13 && (minorPatch >= 18 && minorPatch <= 22)) {
    return TFT_SET_9_5;
  }

  // Set 10: Remix Rumble (13.23 -> 14.5)
  if ((season === 13 && minorPatch >= 23) || (season === 14 && minorPatch <= 5)) {
    return TFT_SET_10;
  }

  // Set 11: Inkborn Fables (14.6 -> 14.14)
  if ((season === 14 && minorPatch >= 6 && minorPatch <= 14)) {
    return TFT_SET_11;
  }

  // Set 12: Magic n' Mayhem (14.15 -> Latest)
  if ((season === 14 && minorPatch >= 15)) {
    return TFT_SET_12;
  }

  return TFT_SET_LATEST;
};

export const TFT_SET_LATEST: TFTSet = getSetFromPatch(patch(RiotPlatformRegion.GLOBAL).current.patchId);

export const getSetFromSetUrlName = (setUrlName: string) => {
  return getSets().find(s => s.setUrlName === setUrlName) || TFT_SET_LATEST;
};

export const getSetFromSetNumber = (setNumber: number) => {
  return getSets().find(s => s.number === setNumber) || TFT_SET_LATEST;
};

export const getSetFromSetCoreName = (setCoreName?: string) => {
  return getSets().find(s => s.setCoreName === setCoreName) || TFT_SET_LATEST;
};