import { QueryOptions, useTeamfightQuery } from "~/graphql";
import { getMeQuery } from "~/graphql/query/getMe/getMe.query";
import { User } from "~/types/general";

interface Return {
  getMe: User;
}

export async function getMe(options: QueryOptions) {
  const result = await useTeamfightQuery<Return>({ query: getMeQuery, options });
  return { ...result, data: computed<User | undefined>(() => result.result.value?.getMe) };
}