<template>
  <!-- This component doesn't need to render any content itself -->
</template>

<script lang="ts" setup>

import { useUserStore } from "~/stores/UserStore";

const userStore = useUserStore();

// Function to inject the ad script into the DOM
const injectAdScript = () => {
  if (!document.getElementById('primisAdScript') && import.meta.client) {  // Ensure the script is only added once
    const script = document.createElement('script');
    script.id = 'primisAdScript';
    script.src = "https://live.primis.tech/live/liveView.php?s=117903&schain=1.0,1!publift.com,01HJ53D2XBZ0Y52GHDYVAHRJRF,1";
    script.type = 'text/javascript';
    script.language = 'javascript';
    script.async = true; // Load the script asynchronously
    document.body.appendChild(script);
  }
};

// Function to remove the ad script from the DOM
const removeAdScript = () => {
  const script = document.getElementById('primisAdScript');
  if (script) {
    script.remove(); // Remove the script from the DOM
  }

  // Remove the iframe and its container
  const iframeContainer = document.getElementById('primisPlayerContainerDiv');
  if (iframeContainer) {
    iframeContainer.parentElement?.remove(); // Remove the parent div containing the iframe
  }
};

// Watcher to dynamically load the ad script when loadAdScript changes
watch(() => userStore.adminMode, (newVal) => {
  if (!newVal) {
    injectAdScript();
  } else {
    removeAdScript();
  }
});

// Inject the ad script when the component is mounted
onMounted(() => {
  if (!userStore.adminMode) {
    injectAdScript();
  }
});

// Optionally remove the ad script when the component is unmounted
onBeforeUnmount(() => {
  removeAdScript();
});
</script>