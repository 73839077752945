<template>
  <div class="error">
    <template v-if="error.statusCode === 404">
      <TeamfightLogo :size="6"/>
      <h1>404 - Looks like you've been tricked!</h1>
      <p>
        This page doesn't exist, click the logo above to head back to the main site.
      </p>
    </template>

    <template v-else>
      <MaintenanceNotice/>

      <div v-if="isDev" class="error__dev">
        <h2 class="error__dev-message">
          {{ error.statusCode }} - {{ error.message }}
        </h2>

        <div class="error__dev-stack" v-html="error.stack"/>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { NuxtError } from "#app";
import MaintenanceNotice from "~/components/MaintenanceNotice.vue";

interface Props {
  error: NuxtError;
}

defineProps<Props>();

const config = useRuntimeConfig();
const isDev = computed(() => config.public.environment === 'development');

</script>

<style lang="scss" scoped>
.error {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  color: white;

  h1 {
    margin-top: 3rem;
  }

  .error__dev {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--UI-Secondary);
    padding: 2rem;
    border: 0.1rem solid var(--UI-Border);
    border-radius: var(--BorderRadius-S);

    .error__dev-status {

    }

    .error__dev-message {
      font-weight: var(--FontWeight-Bold);
    }

    .error__dev-stack {

    }
  }
}
</style>