import { gql } from "@apollo/client/core";
import { FRAGMENT_USER } from "~/graphql/fragments/User.fragment";

export const getMeQuery = gql`
    ${FRAGMENT_USER}

    query GetMe {
        getMe {
            ...FragmentUser
        }
    }
`;
