import { RiotPlatformRegion } from "~/types/riot";

export const getConventionalRegionName = (region: RiotPlatformRegion) => {
  return {
    [RiotPlatformRegion.GLOBAL]: "global",
    [RiotPlatformRegion.BRAZIL]: "br",
    [RiotPlatformRegion.EUROPE_NORTH]: "eune",
    [RiotPlatformRegion.EUROPE_WEST]: "euw",
    [RiotPlatformRegion.JAPAN]: "jp",
    [RiotPlatformRegion.KOREA]: "kr",
    [RiotPlatformRegion.LATIN_AMERICA_NORTH]: "lan",
    [RiotPlatformRegion.LATIN_AMERICA_SOUTH]: "las",
    [RiotPlatformRegion.MIDDLE_EAST]: "me",
    [RiotPlatformRegion.NORTH_AMERICA]: "na",
    [RiotPlatformRegion.OCEANIA]: "oce",
    [RiotPlatformRegion.TURKEY]: "tr",
    [RiotPlatformRegion.RUSSIA]: "ru",
    [RiotPlatformRegion.PHILIPPINES]: "ph",
    [RiotPlatformRegion.SINGAPORE]: "sg",
    [RiotPlatformRegion.THAILAND]: "th",
    [RiotPlatformRegion.TAIWAN]: "tw",
    [RiotPlatformRegion.VIETNAM]: "vn"
  }[region];
};