import { ContextMenuOption, useContextMenu } from "~/stores/ContextMenuStore";

interface Args {
  position: { y: number, x: number };
  options: ContextMenuOption[][];
}

export const openContextMenu = ({ position, options }: Args) => {
  const store = useContextMenu();

  if (options.length > 0) {
    store.positionX = position.x;
    store.positionY = position.y;
    store.options = options;
  }
};