import { defineStore } from "pinia";
import { format } from "timeago.js";

interface State {
  currentTime: number;
}

export const useGeneralStore = defineStore("general", {
  state: (): State => ({
    currentTime: Date.now(),
  }),
  getters: {
    getTimeAgo: (state) => {
      return (date: Date | number) => format(date, undefined, { relativeDate: state.currentTime });
    }
  },
  actions: {
    startTimeInterval() {
      // Important that this only runs on the client, otherwise will infinitely freeze a deployment.
      if (process.client) {
        setInterval(() => {
          this.currentTime = Date.now();
        }, 1000);
      }
    },
  }
});