<template>
  <div :id="id" :data-fuse="fuseId"/>
</template>

<script lang="ts" setup>
interface Props {
  id: string;
  fuseId: string;
}

const props = defineProps<Props>();

onMounted(() => {
  // TODO: Add fusetag types to window.
  // @ts-ignore
  const fusetag = window.fusetag;

  if (fusetag) {
    // Register the tag.
    fusetag.registerZone(props.id);

    // Initialize the page.
    fusetag.que.push(() => {
      fusetag.pageInit();
    });
  }
});
</script>