import { gql } from "@apollo/client/core";
import { FRAGMENT_USER } from "~/graphql/fragments/User.fragment";

export const loginUserMutation = gql`
    ${FRAGMENT_USER}

    mutation LoginUser($email: String!, $password: String!) {
        loginUser(email: $email, password: $password) {
            token
            user {
                ...FragmentUser
            }
        }
    }
`;
