<template>
  <ClientOnly>
    <template v-if="!userStore.isAdmin || !userStore.adminMode">
      <slot/>
    </template>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { useUserStore } from "~/stores/UserStore";

const userStore = useUserStore();
</script>