import validate from "/tmp/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import authentication_45global from "/tmp/build/app/middleware/authentication.global.ts";
import database_45global from "/tmp/build/app/middleware/database.global.ts";
import manifest_45route_45rule from "/tmp/build/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authentication_45global,
  database_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}