<template>
  <transition>
    <div :key="img" :style="{ backgroundImage, filter: blur ? `blur(${blur})` : undefined }" class="background-splash">
      <div class="image-overlay"/>
    </div>
  </transition>
</template>

<script lang="ts" setup>
interface Props {
  img: string;
  blur?: string;
}

const props = defineProps<Props>();
const backgroundImage = computed(() => `url('${props.img}')`);
</script>

<style lang="scss" scoped>
.background-splash {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  max-height: 144rem;
  opacity: 0.2;

  .image-overlay {
    position: absolute;
    inset: 0;
    background: radial-gradient(closest-side, transparent, rgba(20, 22, 39, 1));
  }
}
</style>
