import { RiotPlatformRegion } from "~/types/riot";

export enum Cookie {
  APOLLO_TOKEN = 'apollo:default.token',
  COOKIES_PERMISSION = 'cookiePermission',
  SEARCH_REGION = 'searchRegion',
  DISCORD_NOTIFICATION = 'discordNotification',
}

export const useApolloToken = () => {
  const config = useRuntimeConfig();
  const domain = config.public.environment === 'development' ? 'localhost' : 'teamfight.lol';

  return useCookie(Cookie.APOLLO_TOKEN, { domain, expires: new Date(Date.now() + 6.048e+8) }); // 6.048e+8 is 1 week in milliseconds
};

export const useCookiePermission = () => {
  return useCookie(Cookie.COOKIES_PERMISSION);
};

export const useSearchRegionCookie = () => {
  return useCookie<RiotPlatformRegion>(Cookie.SEARCH_REGION);
};