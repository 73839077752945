import { default as _91_91augmentName_93_9335DRErIYskMeta } from "/tmp/build/app/pages/database/augments/[[setUrlName]]/[[augmentName]].vue?macro=true";
import { default as _91_91setUrlName_93_93IByFdSNo6kMeta } from "/tmp/build/app/pages/database/augments/[[setUrlName]].vue?macro=true";
import { default as _91_91championName_93_93kf3TWLivFmMeta } from "/tmp/build/app/pages/database/champions/[[setUrlName]]/[[championName]].vue?macro=true";
import { default as _91_91setUrlName_93_934QrCiZwGQmMeta } from "/tmp/build/app/pages/database/champions/[[setUrlName]].vue?macro=true";
import { default as _91_91setUrlName_93_93tTCzxTIMzMMeta } from "/tmp/build/app/pages/database/charms/[[setUrlName]].vue?macro=true";
import { default as _91_91itemName_93_93wCaoNAPIhXMeta } from "/tmp/build/app/pages/database/items/[[setUrlName]]/[[itemName]].vue?macro=true";
import { default as _91_91setUrlName_93_93OB1s2gZtSyMeta } from "/tmp/build/app/pages/database/items/[[setUrlName]].vue?macro=true";
import { default as index6VjhY0yt7sMeta } from "/tmp/build/app/pages/index.vue?macro=true";
import { default as indexyZF4GSuXZmMeta } from "/tmp/build/app/pages/leaderboard/[queueType]/[region]/index.vue?macro=true";
import { default as indexxDQJC03uaQMeta } from "/tmp/build/app/pages/leaderboard/[queueType]/[region]/top/index.vue?macro=true";
import { default as _91_91searchTerm_93_93QkfjfUunsiMeta } from "/tmp/build/app/pages/search/[[searchTerm]].vue?macro=true";
import { default as indexPIvqHa2AUGMeta } from "/tmp/build/app/pages/stats/augments/index.vue?macro=true";
import { default as indexqBdXWFKXLpMeta } from "/tmp/build/app/pages/stats/champions/index.vue?macro=true";
import { default as indexydgA2FcFtfMeta } from "/tmp/build/app/pages/stats/comps/index.vue?macro=true";
import { default as indexIw20s1REbzMeta } from "/tmp/build/app/pages/stats/explorer/index.vue?macro=true";
import { default as indexUOmfOeDA2KMeta } from "/tmp/build/app/pages/stats/items/index.vue?macro=true";
import { default as indexJqbuOEzQNsMeta } from "/tmp/build/app/pages/stats/traits/index.vue?macro=true";
import { default as _91summonerName_93t2Y52TMddjMeta } from "/tmp/build/app/pages/summoner/[region]/[summonerName].vue?macro=true";
import { default as indexWmlYAJs32dMeta } from "/tmp/build/app/pages/team-builder/index.vue?macro=true";
export default [
  {
    name: _91_91setUrlName_93_93IByFdSNo6kMeta?.name ?? "database-augments-setUrlName",
    path: "/database/augments/:setUrlName?",
    component: () => import("/tmp/build/app/pages/database/augments/[[setUrlName]].vue").then(m => m.default || m),
    children: [
  {
    name: _91_91augmentName_93_9335DRErIYskMeta?.name ?? "database-augments-setUrlName-augmentName",
    path: ":augmentName?",
    component: () => import("/tmp/build/app/pages/database/augments/[[setUrlName]]/[[augmentName]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91_91setUrlName_93_934QrCiZwGQmMeta?.name ?? "database-champions-setUrlName",
    path: "/database/champions/:setUrlName?",
    component: () => import("/tmp/build/app/pages/database/champions/[[setUrlName]].vue").then(m => m.default || m),
    children: [
  {
    name: _91_91championName_93_93kf3TWLivFmMeta?.name ?? "database-champions-setUrlName-championName",
    path: ":championName?",
    component: () => import("/tmp/build/app/pages/database/champions/[[setUrlName]]/[[championName]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91_91setUrlName_93_93tTCzxTIMzMMeta?.name ?? "database-charms-setUrlName",
    path: "/database/charms/:setUrlName?",
    component: () => import("/tmp/build/app/pages/database/charms/[[setUrlName]].vue").then(m => m.default || m)
  },
  {
    name: _91_91setUrlName_93_93OB1s2gZtSyMeta?.name ?? "database-items-setUrlName",
    path: "/database/items/:setUrlName?",
    component: () => import("/tmp/build/app/pages/database/items/[[setUrlName]].vue").then(m => m.default || m),
    children: [
  {
    name: _91_91itemName_93_93wCaoNAPIhXMeta?.name ?? "database-items-setUrlName-itemName",
    path: ":itemName?",
    component: () => import("/tmp/build/app/pages/database/items/[[setUrlName]]/[[itemName]].vue").then(m => m.default || m)
  }
]
  },
  {
    name: index6VjhY0yt7sMeta?.name ?? "index",
    path: "/",
    component: () => import("/tmp/build/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexyZF4GSuXZmMeta?.name ?? "leaderboard-queueType-region",
    path: "/leaderboard/:queueType()/:region()",
    component: () => import("/tmp/build/app/pages/leaderboard/[queueType]/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: indexxDQJC03uaQMeta?.name ?? "leaderboard-queueType-region-top",
    path: "/leaderboard/:queueType()/:region()/top",
    component: () => import("/tmp/build/app/pages/leaderboard/[queueType]/[region]/top/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91searchTerm_93_93QkfjfUunsiMeta?.name ?? "search-searchTerm",
    path: "/search/:searchTerm?",
    component: () => import("/tmp/build/app/pages/search/[[searchTerm]].vue").then(m => m.default || m)
  },
  {
    name: indexPIvqHa2AUGMeta?.name ?? "stats-augments",
    path: "/stats/augments",
    component: () => import("/tmp/build/app/pages/stats/augments/index.vue").then(m => m.default || m)
  },
  {
    name: indexqBdXWFKXLpMeta?.name ?? "stats-champions",
    path: "/stats/champions",
    component: () => import("/tmp/build/app/pages/stats/champions/index.vue").then(m => m.default || m)
  },
  {
    name: indexydgA2FcFtfMeta?.name ?? "stats-comps",
    path: "/stats/comps",
    component: () => import("/tmp/build/app/pages/stats/comps/index.vue").then(m => m.default || m)
  },
  {
    name: indexIw20s1REbzMeta?.name ?? "stats-explorer",
    path: "/stats/explorer",
    component: () => import("/tmp/build/app/pages/stats/explorer/index.vue").then(m => m.default || m)
  },
  {
    name: indexUOmfOeDA2KMeta?.name ?? "stats-items",
    path: "/stats/items",
    component: () => import("/tmp/build/app/pages/stats/items/index.vue").then(m => m.default || m)
  },
  {
    name: indexJqbuOEzQNsMeta?.name ?? "stats-traits",
    path: "/stats/traits",
    component: () => import("/tmp/build/app/pages/stats/traits/index.vue").then(m => m.default || m)
  },
  {
    name: _91summonerName_93t2Y52TMddjMeta?.name ?? "summoner-region-summonerName",
    path: "/summoner/:region()/:summonerName()",
    component: () => import("/tmp/build/app/pages/summoner/[region]/[summonerName].vue").then(m => m.default || m)
  },
  {
    name: indexWmlYAJs32dMeta?.name ?? "team-builder",
    path: "/team-builder",
    component: () => import("/tmp/build/app/pages/team-builder/index.vue").then(m => m.default || m)
  }
]