import { useTeamfightMutation } from "~/graphql";
import { loginUserMutation } from "~/graphql/mutation/loginUser/loginUser.mutation";
import { User } from "~/types/general";

interface Return {
  loginUser: {
    token: string;
    user: User;
  };
}

interface Variables {
  email: string;
  password: string;
}

export async function loginUser(variables: Variables) {
  const result = await useTeamfightMutation<Return, Variables>({ mutation: loginUserMutation, variables });
  return { ...result, data: computed(() => result.result?.data?.loginUser) };
}