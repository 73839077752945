import { getMe } from "~/graphql/query/getMe/getMe";
import { useApolloToken } from "~/helpers/cookie";
import { useUserStore } from "~/stores/UserStore";

// Unlike navigation guards in the vue-router docs, a third next() argument is not passed, and redirects or route cancellation is handled by returning a value from the middleware. Possible return values are:
// nothing - does not block navigation and will move to the next middleware function, if any, or complete the route navigation
// return navigateTo('/') or return navigateTo({ path: '/' }) - redirects to the given path and will set the redirect code to 302 Found if the redirect happens on the server side
// return navigateTo('/', { redirectCode: 301 }) - redirects to the given path and will set the redirect code to 301 Moved Permanently if the redirect happens on the server side

export default defineNuxtRouteMiddleware(async (to, from) => {
  const ADMIN_ROUTE_NAMES: string[] = [];
  const VIP_ROUTE_NAMES: string[] = [];

  if ([...ADMIN_ROUTE_NAMES, ...VIP_ROUTE_NAMES].includes(to.name as string)) {
    const userStore = useUserStore();

    if (!userStore.user) {
      const authToken = useApolloToken();

      if (authToken.value) {
        await getMe({ resolve: true }).then(({ data }) => {
          if (data.value) {
            userStore.user = data.value;
            userStore.adminMode = true;
          }
        });
      }
    }

    // Block non-admins from accessing Admin-Only routes.
    if (!userStore.user || (ADMIN_ROUTE_NAMES.includes(to.name as string) && !userStore.user.isAdmin) || (VIP_ROUTE_NAMES.includes(to.name as string) && !userStore.user.isVIP && !userStore.user.isAdmin)) {
      return abortNavigation();
    }
  }
});