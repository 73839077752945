import { CharmType } from "~/types/database";

const cdnUrl = "https://cdn.teamfight.lol";

export const getTFTChampionTileUrl = (apiName?: string) => {
  if (!apiName) return getFallbackIconUrl();

  let name = apiName;
  if (apiName.startsWith('TFT9_Ryze')) name = 'TFT9_Ryze';

  return `${cdnUrl}/tft/champion-tile/${name}.png`;
};

export const getTFTAbilityUrl = (apiName?: string) => {
  if (!apiName) return getFallbackIconUrl();

  let name = apiName;
  if (apiName.startsWith('TFT9_Ryze')) name = 'TFT9_Ryze';

  return `${cdnUrl}/tft/champion-ability/${name}.png`;
};

export const getTFTChampionSplashUrl = (apiName?: string) => {
  if (!apiName) return getFallbackIconUrl();

  let name = apiName;
  if (apiName.startsWith('TFT9_Ryze')) name = 'TFT9_Ryze';

  const neutralChampions = ["TFT_MurkwolfMini", "TFT_Krug", "TFT_BlueGolem", "TFT_Murkwolf", "TFT_RiftHerald", "TFT_RazorbeakMini", "TFT_Razorbeak", "TFT_ElderDragon"];
  if (neutralChampions.includes(apiName)) return getTFTChampionTileUrl(apiName);

  return `${cdnUrl}/tft/champion-splash/${name}.png`;
};

export const getTFTItemUrl = (apiName?: string, setCoreName?: string) => {
  if (!apiName || !setCoreName) return getFallbackIconUrl();
  return `${cdnUrl}/tft/item/${setCoreName}/${apiName}.png`;
};

export const getTFTAugmentUrl = (apiName?: string, setCoreName?: string) => {
  if (!apiName || !setCoreName) return getFallbackIconUrl();
  return `${cdnUrl}/tft/augment/${setCoreName}/${apiName}.png`;
};

export const getTFTTraitUrl = (apiName?: string) => {
  if (!apiName) return getFallbackIconUrl();
  return `${cdnUrl}/tft/trait/${apiName}.png`;
};

export const getTFTCharmUrl = (type: CharmType) => {
  return `${cdnUrl}/tft/charm/${type.toLowerCase()}.png`;
};

export const getSummonerProfileIconUrl = (profileIconId?: number) => {
  if (!profileIconId) return getFallbackIconUrl();
  return `${cdnUrl}/riot/profile-icon/${profileIconId}.jpg`;
};

export const getLittleLegendImageUrl = (contentId?: string) => {
  if (!contentId) return getFallbackIconUrl();
  return `${cdnUrl}/tft/little-legend/${contentId}.png`;
};

export const getTFTRegaliaIconUrl = (rank: string) => {
  return `${cdnUrl}/riot/regalia/${rank}.png`;
};

export const getFallbackIconUrl = () => {
  return `${cdnUrl}/riot/profile-icon/29.jpg`;
};

export const getCDNImageUrl = (path: string) => {
  return `${cdnUrl}${path}`;
};