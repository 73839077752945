import { defineStore } from "pinia";
import { login } from "~/stores/UserStore/actions/login";
import { logout } from "~/stores/UserStore/actions/logout";
import { User } from "~/types/general";

interface State {
  user: User | null;
  adminMode: boolean;
}

export const useUserStore = defineStore("user", {
  state: (): State => ({
    user: null,
    adminMode: false
  }),

  getters: {
    isAdmin: (state) => state.user && state.user.isAdmin,
    isVIP: (state) => state.user && (state.user.isAdmin || state.user.isVIP)
  },

  actions: { login, logout }
});
