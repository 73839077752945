<template>
  <div v-if="notice" :class="notice.severity.toLowerCase()" class="notice-banner">
    <div class="notice-banner__badge">
      <div class="notice-banner__badge-text">
        {{ notice.severity }}
      </div>
    </div>
    <div v-html="notice.text"/>
  </div>
</template>

<script lang="ts" setup>
import { getPlatformNotice } from "~/graphql/query/getPlatformNotice/getPlatformNotice";

const { data: notice } = await getPlatformNotice({ prefetch: false });
</script>

<style lang="scss" scoped>
.notice-banner {
  width: 100%;
  padding: 1rem 20%;
  background-color: indianred;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  text-align: center;
  z-index: 10;

  &.info {
    background-color: rgba(33, 150, 243, 0.8);
  }

  &.warning {
    background-color: rgba(251, 140, 0, 0.8);
  }

  &.error {
    background-color: rgba(207, 102, 121, 0.8);
  }

  &.success {
    background-color: rgba(76, 175, 80, 0.8);
  }


  .notice-banner__badge {
    padding: 0.4rem 0.6rem;
    border: 0.2rem solid white;
    border-radius: 1rem;
    margin-right: 1rem;
    font-weight: var(--FontWeight-Bold);

    .notice-banner__badge-text {
      margin-top: 0.1rem;
      text-wrap: nowrap;
    }
  }
}
</style>